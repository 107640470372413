import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

    return (
      <>
        {/* <CustomNavLinkSmall>
          <Span>
            <Link key="faq" to="/faq" style={{whiteSpace:"pre-wrap"}}>
              {t("FAQ")}
            </Link>
          </Span>
        </CustomNavLinkSmall> */}
        {/* <CustomNavLinkSmall>
          <Span>
            <Link to="/o-nas">{t("AboutUs")}</Link>
          </Span>
        </CustomNavLinkSmall> */}
        {/* <CustomNavLinkSmall>
          <Span>
            <Link to="/blog">{t("Blog")}</Link>
          </Span>
        </CustomNavLinkSmall> */}
        {/* <CustomNavLinkSmall>
          <Span>
            <Link to="/cennik">{t("PriceList")}</Link>
          </Span>
        </CustomNavLinkSmall> */}
        {/* <CustomNavLinkSmall>
          <Span>
            <a style={{
              // fontWeight: "bold",
              fontStyle: "italic"}} 
                href="https://www.facebook.com/profile.php?id=100089234239681"
                target='_blank'>
                  {t("Facebook")}&nbsp;
                  <img src={"img/svg/link.svg"} width="11px" height="11px"/>
            </a>
          </Span>
        </CustomNavLinkSmall>  */}
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
          <Link to="/kontakt">
            <Button>{t("Contact")}</Button>
          </Link>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection style={{ paddingTop: '2.2em' }}>
      <Container>
        <Row justify="space-between"  >
          <LogoContainer to="/" aria-label="homepage" style={{ marginTop: '-20px' }}>
            {/* LOGO circle  */}
            <SvgIcon src="domlux_logo.png"  width="217px" height="100px" /> 
            {/* <SvgIcon src="logo.png" width="217px" height="66px" />  Logo rectange*/}
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
