const BasicColors = {
  primary: '#e8ab48',
  secondary:  '#e8ab48',
  secondaryLight:'#e8ab48',
  white: '#fff',
  black: '#000',
  gray: '#404041'  
}

export const COLORS = {
    bodyBackground: BasicColors.white,

    //GLOBAL STYLES 
    header: BasicColors.primary,
    paragrpah: BasicColors.gray,
    hover: BasicColors.primary, 
    boxShadow: BasicColors.secondary,
    anchor: BasicColors.secondary,
    anchorHover: BasicColors.secondary,
    //HEADER
    svgFill:  BasicColors.secondary,
    navLink:  BasicColors.gray,
    label: BasicColors.gray,
    // FOOTER 
    footerTitle:  BasicColors.primary,
    footerLink: BasicColors.secondaryLight,
    footerLabel: BasicColors.primary,
    footerDivText: BasicColors.gray,
    footerChat: BasicColors.primary,
    footerChatBottom: BasicColors.primary,
    footerLanguage: BasicColors.primary,
    //BUTTON
    buttonBackground:  BasicColors.secondary,
    buttonColor: BasicColors.secondary
}